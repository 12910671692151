/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/* Para tarjeta 3d */
.embossedCardContainer {
    width: 100%!important;
    min-height: 80vh!important;
    margin-bottom: 3%!important;
    padding-top: 5%!important;
  }
  .embossedCard-item-card-shadow, .embossedCard-item-card-shadow-btn {
    box-shadow: #a5a5a5 5px 5px 8px 7px!important;
    border-radius: 16px!important;
  }
  .embossedCard-item-card-shadow-btn{
    position: absolute;
    left: 0;
  }
  
  .embossedCard-item-card {
    padding: 3%!important;
    padding-top: 50px!important;
    padding-bottom: 50px!important;
    border-radius: 16px!important;
    min-height: 70vh!important;
    background-color: #f7f8fc!important;
    border: none!important;
    box-shadow: #fff -6px -4px 4px 5px!important;
  }

  .logo-text{
    font-family: fantasy !important;
    letter-spacing: 3px !important;
  }